import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import useSelector from '../redux/typedHooks';
import { LayoutWithMainContent } from '../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../lib/constants';
import { actions as carsActions, CarModelStateType } from '../redux/modules/cars';
import { useSendGTMEventOnce } from '../hooks/useSendGTMEventOnce';
import { NAFGTMEvent } from '../../../types/GTM/NAFGTMEvent';
import { ModelDetailPage } from './Elbilguiden/ModelDetailPage';
import createAPIQuery from '../lib/createURLQueries';
import { UrlParamsType } from '../../../types/urlParamsType';
import { InternalSearchCardType } from '../components/layout/InternalSearchMetaData';
import { NonEvModelPage } from './NonEvModelPage';
import { useWindowLocation } from '../hooks/useWindowLocation';
import { NotFound } from '../loadable-elements/NotFound';

interface Props {
  carSlug: string;
}

export const path = 'bilmodell/:carSlug';

const BilModell = ({ carSlug }: Props) => {
  const appInsights = useAppInsightsContext();
  useTrackMetric(appInsights, 'Bilmodell');
  const dispatch = useDispatch();

  const [isCarFetched, setIsCarFetched] = useState(false);
  const [isAdditionalModelsFetched, setIsAdditionalModelsFetched] = useState<boolean>(false);

  const cars = useSelector((state) => state.cars.data[0]);
  const car = useSelector((state) => state.cars.mappedData[carSlug]);

  const {
    data: carModelState,
    meta: { isUpdating },
  }: CarModelStateType = car || { data: undefined, meta: { isUpdating: true } };

  const { brandName, modelName, vehicleModelPictures, meta, id, slug, chassis } = carModelState || {};

  const isElectric = useSelector(
    (state) => state.cars.mappedData[slug]?.data?.fuelCategory?.includes('Elektrisk') || false,
  );

  useEffect(() => {
    if (cars.length < 10 && !isAdditionalModelsFetched && chassis) {
      const searchParams: UrlParamsType = {
        filter: {
          chassis,
          fuelCategories: ['elektrisk'],
        },
      };
      const queries = createAPIQuery(searchParams);
      dispatch(carsActions.getCars.request(queries));
      setIsAdditionalModelsFetched(true);
    }
  }, [cars.length, dispatch, isAdditionalModelsFetched, chassis]);

  useEffect(() => {
    if (!isCarFetched) {
      dispatch(carsActions.getOneCar.request(carSlug));
      setIsCarFetched(true);
    }
  }, [carSlug, isCarFetched, dispatch]);

  const [didFetchMeta, setDidFetchMeta] = useState(false);

  useEffect(() => {
    if (!didFetchMeta && carModelState && id && slug) {
      dispatch(carsActions.getCarModelMeta.request(id, { slug }));
      setDidFetchMeta(true);
    }
  }, [meta, carModelState, id, slug, didFetchMeta, dispatch]);

  const displayName = brandName && modelName ? `${brandName} ${modelName}` : null;

  const mainImg =
    vehicleModelPictures?.length > 0 && vehicleModelPictures?.find((pic: any) => pic.type === 'largeFront');
  const imgSrc = mainImg && mainImg.path;

  const seoDescription = meta?.seoConfig?.introduction ? meta.seoConfig.introduction : meta?.ingress;
  const seoTitle = meta?.seoConfig?.title || displayName;
  const windowPath = useWindowLocation();

  useSendGTMEventOnce(
    (carModelState &&
      !isUpdating &&
      carModelState?.fuelCategory &&
      carModelState?.driveTrain && {
        event: NAFGTMEvent.bilmodell,
        motortype: carModelState?.fuelCategory.join('/'),
        drivhjul: carModelState?.driveTrain.join('/'),
      }) ||
      undefined,
    [carModelState],
  );

  if (!carModelState && isCarFetched && !isUpdating) {
    return <NotFound />;
  }

  return (
    <LayoutWithMainContent
      title={displayName ?? 'Bilguiden'}
      description={meta?.ingress || seoDescription}
      url={windowPath}
      imgUrl={(imgSrc && `https:${imgSrc}`) || DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'CarModelPage',
        pageCategory: `Bilguiden/${displayName}` || '',
        contentId: id || '',
      }}
      seoDescription={seoDescription}
      seoTitle={seoTitle}
      isElBilGuide={isElectric}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.Car,
        brandName,
        carModelImage: (imgSrc && `https:${imgSrc}`) || undefined,
      }}
    >
      {isElectric ? <ModelDetailPage slug={carSlug} /> : <NonEvModelPage carSlug={carSlug} />}
    </LayoutWithMainContent>
  );
};

export default BilModell;
